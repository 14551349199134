import axios from "axios";
import {errorStore} from "../store/errorStore";
import {cacheService} from "./cacheService";
import {product} from "../helpers/product";

const {setError} = errorStore;

const baseInstant = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

export class ApiService {
    constructor(_axios = axios.create(), cacheService) {
        this._axios = _axios;
        this._cacheService = cacheService;
        this.product = product.dev.qatar;
    }

    request = (
        path,
        {
            method = "GET",
            data, query, headers,
            cancelToken, onUploadProgress
        } = {},
    ) => {
        return this._axios.request({
            url: path,
            data,
            method,
            params: query,
            headers,
            cancelToken,
            onUploadProgress,
        }).catch(err => {
            if (!err || !err.response || err?.response?.status >= 500) {
                setError("500");
            } else {
                setError(err.response.data.errors?.translation_key);
            }
        });
    };

    _generate_auth_header = () => {
        return {
            "Authorization": this._cacheService.get("Authorization"),
            "Access-Control-Allow-Origin": "*",
            "Product-Name": "jtx",
        };
    };

    _generate_header = () => {
        return {
            "Authorization": this._cacheService.get("Authorization"),
            "Access-Control-Allow-Origin": "*",
            "product": Number(this.product),
        };
    };

    /* AUTH METHODS */

    getCheckEmail = (query) => this.request("users/check_user_with_email/", {
        query,
    });

    getUserById = (id) => this.request(`users/${id}/`, {headers: {...this._generate_header()}});

    setUserLogin = (data) => this.request("users/login/", {method: "POST", data});

    // setFbLoginToken = (data) => this.request("users/login_fb/", {
    //     method: "POST",
    //     data,
    // });

    setFastexLoginToken = (query, data) => this.request("users/login_fastex/", {
        query,
        method: "POST",
        data,
    });

    setGoogleLoginToken = (data) => this.request("users/login_google/", {
        method: "POST",
        data,
    });

    setAppleLoginToken = (data) => this.request("users/login_apple/", {
        method: "POST",
        data,
    });

    getCategores = () => this.request("room/categories/", {
        headers: {
            ...this._generate_header(),
        },
    });

    setNewUser = (data) => this.request("users/", {
        method: "POST",
        headers: {...this._generate_auth_header()},
        data
    });

    setUserLogOut = () => this.request("users/logout/?expire_all=True", {
        method: "POST",
        headers: {...this._generate_header()},
    });

    setUserForgotPassEmail = (data) => this.request("users/forgot_password/", {
        method: "POST",
        headers: {...this._generate_auth_header()},
        data
    });

    setUserCreatePassword = (data) => this.request("users/set_new_password/", {
        method: "POST",
        headers: {...this._generate_auth_header()},
        data
    });

    setUserForgotToken = (data) => this.request("users/reset_password/", {
        method: "POST",
        headers: {...this._generate_auth_header()},
        data
    });

    setUserNameById = (id, data) => this.request(`users/${id}/`, {
        method: "PATCH",
        headers: {...this._generate_header()},
        data,
    });

    setUserEmailVerify = () => this.request("users/user_verification/", {
        method: "POST",
        headers: {...this._generate_auth_header()},
    });

    /* ROOMS METHODS */

    getAllSpaces = (headers) =>
        this.request("room/space_design_list/", {
            headers: {
                ...this._generate_header(),
                headers,
            },
        });

    getMySpaces = (headers) =>
        this.request("room/user_templates/", {
            headers: {
                ...this._generate_header(),
                headers,
            },
        });

    getPortalList = (query, headers) =>
        this.request("room/get_space_list/", {
            query,
            headers: {
                ...this._generate_header(),
                headers,
            },
        });

    set CategoryId(id) {
        this.categoryId = id;
    }

    getAllRooms = (query, headers) =>
        this.request("room/", {
            query,
            headers: {
                ...this._generate_header(),
                headers,
            },
        });

    getRoomSearch = (query, headers) =>
        this.request("room/", {
            query,
            headers: {
                ...this._generate_header(),
                headers,
            },
        });

    getRoomByID = (id, query) =>
        this.request(`room/${id}/`, {
            query,
            headers: {
                ...this._generate_header(),
            },
        });

    // setRoomFromTemplate = (data) =>
    //     this.request("room/create_room_from_template/", {
    //         method: "POST",
    //         headers: {
    //             ...this._generate_header(),
    //         },
    //         data,
    //     });

    setFastexRoomFromTemplate = (data) =>
        this.request("room/create_fastex_room/", {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
            data,
        });
    setUserGuest = () => this.request(`users/guest_token/`);

    setDeleteRoom = (id, headers) =>
        this.request(`room/${id}/`, {
            method: "DELETE",
            headers: {
                ...this._generate_header(),
                headers,
            },
        });

    setRoomName = (id, data, headers) =>
        this.request(`room/${id}/`, {
            method: "PATCH",
            data,
            headers: {
                ...this._generate_header(),
                headers,
            },
        });

    setEcommerceForRoom = (id, ecommerce_domain, ecommerce_id) =>
        this.request(`room/${id}/`, {
            method: "PATCH",
            data: {
                ecommerce_domain,
                ecommerce_id
            },
            headers: {
                ...this._generate_header(),
            },
        });

    setPublishRoom = (id, publish_to) =>
        this.request(`room/publish_room/${id}/`,
            {
                method: "PATCH",
                headers: {
                    ...this._generate_header(),
                },
                data: {publish_type: publish_to}
            });

    setInviteUser = (room_public_id, users_list) =>
        this.request("room/invite_user/",
            {
                method: "POST",
                headers: {
                    ...this._generate_auth_header(),
                },
                data: {
                    room_public_id,
                    users_list
                }
            });

    setClearContent = (data, id) =>
        this.request(`room/reset_room/${id}/`, {
            method: "PATCH",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    setUserAvatar = (data) => this.request("users/update_avatar/", {
        method: "POST",
        headers: {
            ...this._generate_header(),
        },
        data,
    });

    setNewRoom = (data) => this.request("room/", {
        method: "POST",
        headers: {
            ...this._generate_header(),
        },
        data,
    });

    getUserInventory = async (query) => await this.request("room/inventory/", {
        headers: {
            ...this._generate_header(),
        },
        query,
    });

    getUserNft = async () => await this.request("room/nft_inventory/", {
        headers: {
            ...this._generate_header(),
        },
    });

    setEmtyFrame = async (id, data) => await this.request(`room/create_nft_from_empty_frame/${id}/`, {
        method: "PATCH",
        headers: {
            ...this._generate_header(),
        },
        data
    });

    getUserStuff = async () => await this.request("room/stuff_list/", {
        headers: {
            ...this._generate_header(),
        },
    });

    setNewModel = (data, onUploadProgress) => this.request("room/add_model/", {
        method: "POST",
        headers: {
            ...this._generate_header(),
        },
        data,
        onUploadProgress
    });

    delModel = (id) => this.request(`room/delete_model_from_inventory/${id}/`, {
        method: "DELETE",
        headers: {
            ...this._generate_header(),
        },
    });

    setTemplate = (data) => this.request("room/user_templates/", {
        method: "POST",
        headers: {
            ...this._generate_header(),
        },
        data,
    });

    getRTCToken = (data) => this.request("room/generate_agora_token_action/", {
        method: "POST",
        headers: {
            ...this._generate_header(),
        },
        data,
    });

    handleLikeRoom = (roomId) => this.request(`users/like-dislike/${roomId}/`, {
        method: "POST",
        headers: {
            ...this._generate_header(),
        }
    });

    getCheckToken = (query) => this.request(`users/check_token/`, {
        query,
        headers: {
            ...this._generate_header(),
        }
    });

    setMuteUnmute = (data) => this.request("/room/mute_unmute/", {
        method: "POST",
        headers: {
            ...this._generate_header(),
        },
        data,
    });

    getTranslation = (language) => this.request(`/landing_page/localize/?lang=${language}`);

    getCountdown = () => this.request(`/users/current_ruffle/`, {
        headers: {
            ...this._generate_header(),
        }
    });

    getGifts = () => this.request("/gifts/my_gifts/", {
        headers: {
            ...this._generate_header(),
        }
    });

    getDeleteReasons = () => this.request("/users/reasons/", {
        headers: {
            ...this._generate_header(),
        }
    });

    postDeleteReasons = (data) => this.request("/users/delete_profile/", {
        method: "POST",
        headers: {
            ...this._generate_header(),
        },
        data,
    });

    sendOpenRoomEmail = (id) => this.request(`/room/send_email_open_room/${id}/`, {
        headers: {
            ...this._generate_auth_header(),
        },
    });

    getBooks = (id, query) => this.request(`/books/book/read_file/?file_id=${id}`, {
        query,
        headers: {
            ...this._generate_header()
        },

    });

    getUcraftAccesToken = (query) => axios.get(`https://sso.ucraft.ai/api/access-token?${query}`);

    setLogoutUcraft = () => axios.post("https://sso.ucraft.ai/api/logout", {}, {headers: {"Authorization": "Bearer " + cacheService.get("ac_token")}});

    getUcraftUser = () => axios.get("https://sso.ucraft.ai/api/user", {headers: {"Authorization": "Bearer " + cacheService.get("ac_token")}});

    getEcommerceParameters = () => axios.get("https://metadev.pandavr.io/ecommerce/website-parameters/", {
        headers: {
            "http-authorization": this._cacheService.get("ac_token")
        }
    });

    getProducts = (url, id, first, page, categories) => axios.get("https://metadev.pandavr.io/ecommerce/get-products/", {
        params: {website_domain: url, website_id: id, first, page, categories},
        headers: {
            "http-authorization": this._cacheService.get("ac_token"),
        }
    });

    getCategorties = (ecommerceDomain, ecommerceProductId) => axios.get("https://metadev.pandavr.io/ecommerce/get-categories/", {
        params: {website_domain: ecommerceDomain, website_id: ecommerceProductId},
        headers: {
            "http-authorization": this._cacheService.get("ac_token"),
        }
    });

    setNewWebsite = (id) => axios.post("/ecommerce/create-website/", {
        params: {room_public_id: id},
        headers: {
            "http-authorization": this._cacheService.get("ac_token"),
        }
    });
}

const apiService = new ApiService(baseInstant, cacheService);

export default apiService;
