import { observer } from "mobx-react-lite";
import useErrorHandler from "../../hooks/useErrorHandler";

const ErrorContainer = observer(({children})=> {
    useErrorHandler();
    return <>
        {children}
    </>
})

export default ErrorContainer