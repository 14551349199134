import { isIOS } from "react-device-detect"

export const MEDIA_DEVICE_STATUS = {
    enabled: 'enabled',
    disabled: 'disabled',
    notFound: 'notFound',
}

export const MICROPHONE_STATUS = {
    0: 'MUTE',
    1: 'UNMUTE',
    2: 'FREEZE',
    3: 'MUTE_ALL',
}

export const ERROR_MAP = {
    'expired_session': {
      redirectPath: "/",
    },
    'permission_denied': {
      redirectPath: "/",
    },
    'user_deactivated': {
      redirectPath: "/",
    },
    'invalid_credentials': {
      redirectPath: "/",
    },
    'email_not_confirm': {
      redirectPath: "/expired-link",
    },
    'user_does_not_have_permission': {
      modal_title: "“Oops!”",
      redirectPath: "/dashboard",
      isModal: true,
      closeable: true
    },
    'room_does_not_exist': {
      redirectPath: "/404",
    },
    'connection_lost': {
      modal_title: "Connection was lost!",
      isModal: true,
      connected: false
    },
    'user_freezed': {
      modal_title: "Muted",
      isModal: true,
      closeable: true,
      actionBtnOk: true
    },
    "500": {
        redirectPath: "/maintenance",
    }
}

export const CUBE_MAP_IMAGES = [
  "px.webp",
  "nx.webp",
  "py.webp",
  "ny.webp",
  "pz.webp",
  "nz.webp",
]

export const CUBE_MAP_DEFOULT = [
  "./envMap/px.png",
  "./envMap/nx.png",
  "./envMap/py.png",
  "./envMap/ny.png",
  "./envMap/pz.png",
  "./envMap/nz.png",
]

export const DEFOULT_IDLE_VIDEO = '/videos/idle.mp4'

