import React from "react";
import Logo from "../../assets/images/jetex.svg";
import "./style.scss";

const LoaderAir = ({backLayer = false}) => (
    <div className={`loader-content ${backLayer ? "backLayer" : ""}`}>
        <svg version="1.1" id="airplane-loader" xmlns="http://www.w3.org/2000/svg" width="144" height="48"
             viewBox="0 0 144 48">
            <path id="airplane-take-off" fill="#ffffff"
                  d="M59.124,34L56,29h-4l2.947,11H89c1.657,0,3-1.343,3-3s-1.343-3-3-3H78.998L69,18h-4l4.287,16H59.124z"/>
            <rect id="ground" x="52" y="44" fill="#ff6900" width="40" height="4"/>
        </svg>
        <img className="jetex-logo-loader" src={Logo} alt=""/>
        <svg version="1.1" id="airplane-loader" xmlns="http://www.w3.org/2000/svg" width="144" height="48"
             viewBox="0 0 144 48">
            <path id="airplane-landing" fill="#ffffff"
                  d="M59.124,34L56,29h-4l2.947,11H89c1.657,0,3-1.343,3-3s-1.343-3-3-3H78.998L69,18h-4l4.287,16H59.124z"/>
            <rect id="ground" x="52" y="44" fill="#ff6900" width="40" height="4"/>
        </svg>
    </div>
);

export default LoaderAir;
