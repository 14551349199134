import React from "react";
import "./style.sass";

const Sky = ({children, backLayer}) => (
    <div className={`sky-container ${backLayer ? "backLayer" : ""}`}>
        <div className="sky">
            <div className="stars"></div>
            <div className="stars1"></div>
            <div className="stars2"></div>
            <div className="shooting-stars">
                <span className="shooting-stars-airplane"></span>
            </div>
        </div>
        {children}
    </div>
);

export default Sky;
