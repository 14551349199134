import {makeObservable, observable, action} from "mobx";
import {cacheService} from "../services/cacheService";
import apiServices from "../services/apiServices";
import {Languages} from "../constants";

class TranslationStore {
    activeLanguage = Languages[cacheService.get("Language")]?.title || Languages["ENG"].title;
    translationData = [];

    constructor() {
        makeObservable(this, {
            activeLanguage: observable,
            translationData: observable,
            setActiveLanguage: action.bound,
            getTranslationData: action.bound,
        });
    }

    setActiveLanguage(data) {
        this.activeLanguage = data.title;
        cacheService.set("Language", data.title);
    }

    setTranslationData(data) {
        this.translationData = data;
    }

    getTranslationData = async () => {
        try {
            if(!cacheService.get("Language")) {
                cacheService.set("Language", Languages['ENG'].value);
                this.activeLanguage = 'ENG';
            }
            const {data: {data}} = await apiServices.getTranslation(Languages[this.activeLanguage].value);
            document.documentElement.lang = Languages[this.activeLanguage].value;
            // if(Languages[this.activeLanguage].value === 'ar') {
            //     document.documentElement.dir = 'rtl';
            // } else {
            //     document.documentElement.removeAttribute('dir')
            // }
            this.setTranslationData(data)
        } catch (e) {
            console.warn("ERROR: ", e.message);
        }
    }


}

export const translationStore = new TranslationStore();