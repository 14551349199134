import {ReactComponent as Ar} from '../assets/icons/languages/AM.svg';
import {ReactComponent as Ru} from '../assets/icons/languages/RU.svg';
import {ReactComponent as Us} from '../assets/icons/languages/US.svg';
// import {ReactComponent as Ps} from '../assets/icons/languages/PS.svg';

export const Languages = {
    ARM: {title: "ARM", icon: <Ar />, value: "hy"},
    RUS: {title: "RUS", icon: <Ru />, value: "ru"},
    ENG: {title: "ENG", icon: <Us />, value: "eng"},
    // ARA: {title: "ARA", icon: <Ps />, value: "ar"},
}

