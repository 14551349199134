import { makeObservable, observable, action, computed } from 'mobx';
import { ERROR_MAP } from './constatns';

class ErrorStore {
  errors = {};
  errorOptions = null;
  redirectMap = ERROR_MAP;
  constructor() {

    makeObservable(this, {
      errors: observable,
      errorOptions: observable,
      actionBtnOk: computed,
      hasOnModal: computed,
      modalTitle: computed,
      closeable: computed,
      connected: computed,
      modalMessage: computed,
      setError: action.bound,
      reconnect_internet: action.bound,
      closeModal: action.bound,
      getErrorOptions: action.bound,
    });

  }

  getErrorOptions(errorKey) {
    return this.redirectMap[errorKey]
  }

  get actionBtnOk(){
    return this.errorOptions?.actionBtnOk
  }

  get hasOnModal(){
    return !!this.errorOptions?.isModal
  }

  get closeable(){
    return !!this.errorOptions?.closeable
  }

  get modalTitle(){
    return this.errorOptions?.modal_title
  }

  get modalMessage(){
    return this.errorOptions?.errorKey
  }

  get connected(){
    return this.errorOptions?.connected
  }

  closeModal() {
    if(this.closeable) this.errorOptions = {...this.errorOptions, isModal: false}
  }

  reconnect_internet() {
    this.errorOptions = {
      ...this.errorOptions, 
      modal_title:'Connection restored!', 
      connected: true,
      errorKey: "reconnected"
    };

    setTimeout(() => {
      this.errorOptions = null
    }, 1500);
  }

  setError(errorKey, status = true) {
    if(!status) {
      return this.errors = delete this.errors[errorKey]
    }
    this.errors = {...this.errors, [errorKey]: status}
    const errorOptions = this.getErrorOptions(errorKey)
    if(errorOptions){
      this.errorOptions = {...errorOptions, errorKey}
    }else{
      setTimeout(()=> this.setError(errorKey, false), 3000)
    }
  }
}

export const errorStore = new ErrorStore();


