import {makeObservable, observable, action} from "mobx";
import {unityStore} from "./unityStore";

class DashboardStore {
    rooms = [];
    filteredRooms = null;
    spaces = [];
    myEventsActive = "all_events";
    mySpacesActive = false;
    sortType = "";
    searchValue = "";
    countdown = {};
    gifts = [];
    roomsLimit = 0;
    countLimit = 12;
    limit = 12;
    headerWidth = 0;
    isRefreshedBoard = false;

    constructor() {
        makeObservable(this, {
            rooms: observable,
            filteredRooms: observable,
            spaces: observable,
            myEventsActive: observable,
            mySpacesActive: observable,
            sortType: observable,
            searchValue: observable,
            countdown: observable,
            gifts: observable,
            roomsLimit: observable,
            countLimit: observable,
            headerWidth: observable,
            isRefreshedBoard: observable,
            setRooms: action.bound,
            setSpaces: action.bound,
            setMyEventsActive: action.bound,
            setMySpacesActive: action.bound,
            filterDraft: action.bound,
            resetFilterDraft: action.bound,
            setIsLiked: action.bound,
            setSortType: action.bound,
            setSearchValue: action.bound,
            setCountdown: action.bound,
            setGifts: action.bound,
            setRoomsLimit: action.bound,
            setCountLimit: action.bound,
            setClearCountLimit: action.bound,
            setHeaderWidth: action.bound,
            setIsRefreshedBoard: action.bound,
            setLikedRoom: action.bound,
            setOnlineUsersCount: action.bound,
        });
    }

    setRooms(data) {
        this.rooms = [...data];
    }

    setSpaces(data) {
        this.spaces = [...data];
    }

    setMyEventsActive(string) {
        this.myEventsActive = string;
    }

    setMySpacesActive(flag) {
        this.mySpacesActive = flag;
    }

    filterDraft() {
        this.filteredRooms = this.rooms.filter(room => room?.room_state === 2);
    }

    resetFilterDraft() {
        this.filteredRooms = null;
    }

    setIsLiked(roomId, {liked, total_count}) {
        this.rooms.map(room => {
            if (room.public_id === Number(roomId)) {
                room.is_liked = liked;
                room.likes_total_count = total_count;
                unityStore.setIsLiked();
            }
            return room
        })
    }

    setSortType(string) {
        this.sortType = string;
    }

    setSearchValue(string) {
        this.searchValue = string;
    }

    setCountdown(obj) {
        this.countdown = obj;
    }

    setGifts(arr) {
        this.gifts = arr;
    }

    setRoomsLimit(number) {
        this.roomsLimit = number;
    }

    setCountLimit(number) {
        this.countLimit = number;
    }

    setClearCountLimit() {
        this.countLimit = this.limit;
    }

    setHeaderWidth(num) {
        this.headerWidth = num;
    }

    setIsRefreshedBoard(bool) {
        this.isRefreshedBoard = bool;
    }

    setLikedRoom({room_id, likes_total_count}) {
        this.rooms.map(room => {
            if (Number(room_id) === room.public_id) {
                room.likes_total_count = likes_total_count;
                unityStore.setTotalLikes(likes_total_count);
            }
            return room
        })
    }

    setOnlineUsersCount({room_id, online_users_count}) {
        this.rooms.map(room => {
            if (Number(room_id) === room.public_id) {
                room.online_users_count = online_users_count;
            }
            return room
        });
    }
}

export const dashboardStore = new DashboardStore();


