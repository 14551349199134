import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { unityStore } from '../../store/unityStore';

const Room = observer(() => {
  const {setLoadUnity} = unityStore;

  useEffect(() => {
    setLoadUnity(true);
  }, [])

  return (
    <div className="room-container"></div>
  );
});

export default Room;