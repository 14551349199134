import React, {lazy, Suspense, useEffect, useState} from "react";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import routes from "./routes";
import {unityStore} from "./store/unityStore";
import {observer} from "mobx-react-lite";
import DoubleLogin from "./components/Popups/DoubleLogin";
import {authStore} from "./store/authStore";
import {isMobile, isTablet} from "react-device-detect";
import InviteShowPopup from "./components/Popups/InviteShowPopup";
import {translationStore} from "./store/translationStore";
import ErrorModal from "./components/ModalError";
import TransitionRouter from "./components/TransitionRouter";
import LoaderAir from "./components/LoaderAir";
import Sky from "./components/Sky";
import ErrorContainer from "./containers/ErrorContainer";
import RotateDevice from "./components/RotateDevice";
import {loadingStore} from "./store/loadingStore";

const UnityLayout = lazy(() => import("./containers/unityLayout"));

const App = observer(() => {
    const {loadUnity} = unityStore;
    const {doubleLogin, inviteMessage} = authStore;
    const [windowSize, setWindowSize] = useState({availWidth: window.screen.availWidth, availHeight: window.screen.availHeight})

    const {getTranslationData} = translationStore;
    const {translateLoading, unityLoading, cubeMapLoading, toggleTranslateLoading} = loadingStore;

    useEffect(async () => {
        toggleTranslateLoading(true);
        await getTranslationData();
        toggleTranslateLoading(false);
        window.addEventListener("orientationchange", () => {
            setWindowSize({availWidth: window.screen.availWidth, availHeight: window.screen.availHeight});
        })
        return () => {
            document.removeEventListener("orientationchange", () => {
                setWindowSize({availWidth: window.screen.availWidth, availHeight: window.screen.availHeight});
            })
        }
    }, []);

    return (
        <Suspense fallback={null}>
            <BrowserRouter>
                <TransitionRouter>
                    <ErrorContainer>
                        <Suspense fallback={<LoaderAir/>}>
                            <Routes>
                                {routes.map((item, index) => (
                                    <Route
                                        key={index}
                                        path={item.path}
                                        element={<item.component/>}
                                        title={item.title}
                                        exact
                                    />
                                ))}
                                <Route path="*" element={<Navigate to="/404" replace/>}/>
                            </Routes>
                        </Suspense>
                    </ErrorContainer>
                </TransitionRouter>
                <Suspense fallback={null}>
                    {loadUnity && !isMobile && <UnityLayout/>}
                </Suspense>
                <Sky backLayer={!(translateLoading || unityLoading || cubeMapLoading)}>
                    <LoaderAir/>
                </Sky>
                <Suspense fallback={null}>
                    {isMobile && !isTablet && windowSize.availHeight < windowSize.availWidth && <RotateDevice/>}
                    {doubleLogin && <DoubleLogin/>}
                    {!!inviteMessage && <InviteShowPopup/>}
                </Suspense>
            </BrowserRouter>
            <ErrorModal/>
        </Suspense>
    );
});

export default App;

