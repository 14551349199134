import {makeObservable, observable, action} from 'mobx';

class UnityStore {
    loadUnity = false;
    isActive = false;
    roomID = 0;
    roomName = '';
    avatarUrl = '';
    mouseOn = false;
    isVideoActive = false;
    portalToggle = false;
    audioTrack = null;
    videoTrack = null;
    screenTrack = null;
    enabledVideo = false;
    enabledScreen = true;
    env = {};
    webGlContext = null;
    remoteUsersScreen = null;
    allUsers = null;
    currentStep = 0;
    endStep = 0;
    envMap = '';
    isActiveMobile = false;
    cubeEnvId= 0;
    cubeMapImages = null;
    hooryToken = "";
    totalLikes = 0;
    isLiked = false;

    constructor() {
        makeObservable(this, {
            cubeMapImages: observable,
            loadUnity: observable,
            isActive: observable,
            mouseOn: observable,
            avatarUrl: observable,
            roomID: observable,
            roomName: observable,
            isVideoActive: observable,
            portalToggle: observable,
            audioTrack: observable,
            videoTrack: observable,
            screenTrack: observable,
            enabledVideo: observable,
            enabledScreen: observable,
            env: observable,
            webGlContext: observable,
            remoteUsersScreen: observable,
            allUsers: observable,
            envMap: observable,
            isActiveMobile: observable,
            cubeEnvId: observable,
            hooryToken: observable,
            setLoadUnity: action.bound,
            setMouseOn: action.bound,
            setRoomID: action.bound,
            setAvatarUrl: action.bound,
            setIsActive: action.bound,
            setRoomName: action.bound,
            setIsVideoActive: action.bound,
            setPortalToggle: action.bound,
            setAudioTrack: action.bound,
            setVideoTrack: action.bound,
            setScreenTrack: action.bound,
            setEnabledVideo: action.bound,
            setEnabledScreen: action.bound,
            setEnv: action.bound,
            setWebGlContext: action.bound,
            setAllUsers: action.bound,
            currentStep: observable,
            endStep: observable,
            setCurrentStep: action.bound,
            setEndStep: action.bound,
            setEnvMap: action.bound,
            setIsActiveMobile: action.bound,
            setHooryToken: action.bound,
            setTotalLikes: action.bound,
            setIsLiked: action.bound
        });
    }
    setIsLoading(bool) {
        return this.isLoading = bool
    }

    setCurrentStep(step) {
        this.currentStep = step;
    }

    setEndStep(step) {
        this.endStep = step;
    }

    setLoadUnity(flag) {
        this.loadUnity = flag;
    }

    setWebGlContext(data) {
        this.webGlContext = data;
    }

    setIsActive(flag) {
        this.isActive = flag;
    }

    setMouseOn(flag) {
        this.mouseOn = flag;
    }

    setRoomID(number) {
        this.roomID = number;
    }

    setRoomName(string) {
        this.roomName = string;
    }

    setAvatarUrl(path) {
        this.avatarUrl = path;
    }

    setIsVideoActive(flag) {
        this.isVideoActive = flag;
    }

    setPortalToggle(flag) {
        this.portalToggle = flag;
    }

    setAudioTrack(track) {
        this.audioTrack = track;
    }

    setVideoTrack(track) {
        this.videoTrack = track;
    }

    setScreenTrack(track) {
        this.screenTrack = track;
    }

    setEnabledVideo(flag) {
        this.enabledVideo = flag;
    }

    setEnabledScreen(flag) {
        this.enabledScreen = flag;
    }

    setEnv(data) {
        this.env = data;
    }

    setRemoteUsersScreen(data) {
        this.remoteUsersScreen = data;
    }

    setAllUsers(data) {
        this.allUsers = data;
    }

    setEnvMap(path) {
        this.envMap = path;
    }

    setIsActiveMobile(bool) {
        this.isActiveMobile = bool;
    }

    setHooryToken(str) {
        this.hooryToken = str;
    }

    setTotalLikes(num) {
        this.totalLikes = num;
    }

    setIsLiked(bool) {
        this.isLiked = bool;
    }

}

export const unityStore = new UnityStore();
