import { makeObservable, observable, action } from 'mobx';

class LoadingStore {
    translateLoading = false;
    unityLoading = false;
    cubeMapLoading = false;

    constructor() {
        makeObservable(this, {
            translateLoading: observable,
            unityLoading: observable,
            cubeMapLoading: observable,
            toggleTranslateLoading: action.bound,
            toggleUnityLoading: action.bound,
            toggleCubeMapLoading: action.bound,
        });
    }

    toggleTranslateLoading(bool) {
        this.translateLoading = bool;
    }

    toggleUnityLoading(bool) {
        this.unityLoading = bool;
    }

    toggleCubeMapLoading(bool) {
        this.cubeMapLoading = bool;
    }
}

export const loadingStore = new LoadingStore();

