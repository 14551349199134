import React, {useEffect, useState} from "react";
import apiService from "../services/apiServices";
import {cacheService} from "../services/cacheService";
import {authStore} from "../store/authStore";
import {observer} from "mobx-react-lite";
import useWebSocket from "react-use-websocket";
import { errorStore } from "../store/errorStore";
import { dashboardStore } from "../store/dashboardStore";

export const Hoc = (Component) => {
    return observer((props) => {
        const Init = () => {
            const token = cacheService.getAuthToken() || " ";
            const [checkToken, setCheckToken] = useState(false)

            const {lastMessage} = useWebSocket(process.env.REACT_APP_WEBSOCKET_URL + token.trim()) ?? false;
            const {setDoubleLogin, setInviteMessage} = authStore;
            const {setError, reconnect_internet} = errorStore;
            const {setLikedRoom, setOnlineUsersCount} = dashboardStore;

            useEffect(async () => {
                if(!checkToken) {
                    try {
                        await apiService.getCheckToken({jtx: "True"});
                        setCheckToken(true)
                    } catch (e) {
                        setCheckToken(false)
                        console.warn("ERROR: ", e.message);
                    }
                }

                if (lastMessage && checkToken) {
                    const { data:{command, response} } = JSON.parse(lastMessage.data);
                    switch (command) {
                        case "double_login":
                            setDoubleLogin(true);
                            break;
                        case "update_online_users_count":
                            setOnlineUsersCount(response.data[0]);
                            break;
                        case "user_room_invite":
                            setInviteMessage(response.message);
                            break;
                        case "likes_total_count":
                            setLikedRoom(response);
                            break;
                    }
                }

            }, [lastMessage]);

            
            useEffect(()=> {
                window.addEventListener('online', reconnect_internet);
                window.addEventListener('offline', () => setError('connection_lost'));
                return ()=> {
                    window.removeEventListener('online', reconnect_internet);
                    window.removeEventListener('offline', setError);
                }
            }, [])
        };

        Init();

        return <Component {...props}/>;
    });
};

