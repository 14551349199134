import { useEffect } from 'react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { errorStore } from '../store/errorStore';
import { unityStore } from '../store/unityStore';

const useErrorHandler = () => {
  const {errors, getErrorOptions, setError} = errorStore;
  const {setIsActive} = unityStore
  const navigate = useNavigate()
  const condidate = useMemo(()=> Object.keys(errors).map(item => errors[item] ? item : false)[0], [errors])
  const options = useMemo(()=> {
    const errorOptions = getErrorOptions(condidate)
    return errorOptions || {redirectPath: false}
  }, [errors])

  useEffect(() => {
    if(options.redirectPath){
      setIsActive(false)
      setError(condidate, false)
      navigate(options.redirectPath)
    }
  }, [options])
};

export default useErrorHandler;