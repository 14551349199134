import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/styles/scss/style.scss";

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById("root"),
);
