import {useLocation} from "react-router-dom";
import React, {useEffect, useRef} from "react";
import gsap from "gsap";
import "./index.scss";
import Sky from "../Sky";
import { isDesktop, isTablet } from "react-device-detect";
import isFirefox from "../../helpers/isFirefox";

const TransitionRouter = ({children}) => {
    const location = useLocation();
    const containerRef = useRef(null);
    // const height = useDocumentHeight();

    // useEffect(() => {
    //     document.body.style.height = height + "px";
    // }, [])

    useEffect(() => {
        const timeline = gsap.timeline();
        timeline.from(containerRef.current, {
            duration: 0.3,
            opacity: 0,
            x: "100vw",
            ease: "power2.out",
        });
        timeline.to(containerRef.current, {
            duration: 0.3,
            opacity: 1,
            x: "0",
            ease: "power2.out",
        });

        return () => {
            timeline.kill();
        };
    }, [location]);

    return (
        <Sky>
            <div className={`layer-content ${!isDesktop ? "mobile" : ""} ${isTablet ? "tablet" : ""} ${isFirefox()? 'firefox' : ''}`} ref={containerRef}>
                {children}
            </div>
        </Sky>
    );
};

export default TransitionRouter;