export const product = {
    dev: {
        dsc: "42858503",
        bet: "2466536236",
        nyce: "834013950",
        panda: "204189302",
        fastex: "216906112",
        qatar: "222162273"
    },
    prod: {
        dsc: "168807894",
        bet: "186157564",
        nyce: "828345941",
        panda: "807527474",
        fastex: "95942005"
    }
}