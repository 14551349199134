import React from "react";
import "./index.scss";
import Sky from "../Sky";

const RotateDevice = () => {
    return (
        <Sky>
            <div className="rotate-container">
                <span className="rotate-img"/>
            </div>
        </Sky>
    )
}

export default RotateDevice;